import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../../components/common/layout/layout';
import SEO from '../../components/common/SEO/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout>
        <SEO title="Condiciones de envío" description="Condiciones de envío de AlmaySer Terapias Florales" robots="noindex nofollow" />
        <div className="docs">
            {children}
        </div>
    </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Política de compras`}</h1>
    <h2 id={"formas-de-pago"}>Formas de pago</h2>
    <p>{`El proceso de pago de los productos florales se realiza a través de la plataforma STRIPE, la cual nos proporciona total seguridad y confianza en el proceso de pago.`}</p>
    <p>{`La forma de pago de las consultas florales es a través de TRANSFERENCIA BANCARIA a IBAN ES7101825326090200674939 BBVA o BIZUM al +34 634 46 43 16.`}</p>
    <h2 id={"condiciones-de-envio"}>Condiciones de envío</h2>
    <p>{`Los gastos de envío son gratuitos. Se realizan envíos de productos en todo el territorio de España Península, incluidas Baleares y Canarias con un suplemento adicional de +10€.`}</p>
    <p>{`*Actualmente los envíos están disponibles sólo en España Península.`}</p>
    <p>{`Los productos están elaborados por María Casanovas, Practitioner acreditada, y los envíos son gestionados por la empresa de Transporte, mensajería y paquetería urgente TIPSA la cual dispone de un sistema de seguimiento del paquete donde podrás saber en qué estado se encuentra el envío.`}</p>
    <p>{`El plazo de entrega es de 24h a 48h desde el momento de la confirmación del pago, excepto si el pedido se realiza durante el fin de semana, que se puede ampliar unos días.`}</p>
    <p>{`Para cualquier duda, pregunta o incidencia puedes ponerte en contacto a través de `}<a parentName="p" {...{
        "href": "mailto:info@almayser.es"
      }}>{`info@almayser.es`}</a>{` o al +34634464316.`}</p>
    <h3 id={"comentarios-y-devoluciones"}><b>Comentarios y devoluciones</b></h3>
    <p>{`Puedes enviar todos tus comentarios, sugerencias, quejas u opiniones al correo electrónico `}<a parentName="p" {...{
        "href": "mailto:info@almayser.es"
      }}>{`info@almayser.es`}</a>{`, y con mucho gusto leeré tus palabras.`}</p>
    <p>{`Al ser los preparados florales un producto único y personalizado, no se admiten devoluciones, pero por favor, hazme llegar el motivo a `}<a parentName="p" {...{
        "href": "mailto:maria@almayser.es"
      }}>{`maria@almayser.es`}</a>{` y trataré de ayudarte en todo lo posible.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      